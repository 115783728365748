import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../containers/layout';
import { Modal } from '../containers/modal';
import { PraxisTemplate } from '../pageTemplates/praxis';
import { GraphQLData } from '../types';

interface PraxisProps {
  data: GraphQLData;
  location: any;
}

const Praxis = ({ location, data }: PraxisProps) => {
  const { subheader, meta } = data.strapi?.praxi || {};
  const { title = 'Praxis', description } = meta ?? {};

  return (
    <Layout>
      <PraxisTemplate data={data} location={location} />
      <Modal team />
      <Modal services level="2" />
    </Layout>
  );
};

export const query = graphql`
  query Praxis {
    strapi {
      praxi {
        meta {
          title
          description
        }
        whyTitle
        therapistTitle
        subheader
        therapistDescription
        warumCarousel {
          id
          description
          title
          image {
            alternativeText
            caption
            url
            file {
              ...Image
            }
          }
        }
      }
      therapists(sort: "order:ASC") {
        id
        title
        forename
        surname
        image {
          alternativeText
          url
          file {
            ...Image
          }
        }
      }
    }
  }
`;

export default Praxis;
